
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword} from '../../api/user.api';


import './resetearcontrasena.scss';






const Presetearcontrasena = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  

  
  return (
    <div id="id653fd728c239522da2e3aa43" >

      <div id="fgfg" className="">
      
      <div id="i99szh" className="">
      
        <a
          target=""
          className=""
          id="i9ymbj"
          href="/"
        >
          
      <img
        className=""
        id="i1d1f1"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr0oo7y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ix5d2" className="">
      
      <div id="iw403" className="">
      
      <p className="" id="iegvfq">
        <span>Resetear Contraseña</span>
      </p>
      
      <div id="ix5hu" className="">
      
      <form
        id="iliut"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyPassword = e.target.newPassword?.value;
					const loftyConfirmPassword = e.target.confirmPassword?.value
					const res = await loftyResetPassword({ password: loftyPassword, confirmPassword: loftyConfirmPassword, token: match.params.undefined });
					alert("Se reseteo la contraseña de manera exitosa")
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="i4vrn" className="">
      
      <label id="icqwn" className="labeliniciosesion ">
      <span>Contraseña</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="newPassword"
        type="password"
        className="inputdark "
        id="i9g5e"
      />
      
      </div>
      
      <div id="iybfu" className="">
      
      <label id="idgkf" className="labeliniciosesion ">
      <span>Confirmar contraseña</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="confirmPassword"
        type="password"
        className="inputdark "
        id="i6djy"
      />
      
      </div>
      
      <button
        type="submit"
        name=""
        id="iw95z"
        className="buttoniniciosesion "
        
      >
      <span>Enviar</span>
      </button>
      
      </form>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Presetearcontrasena);
  
