
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';


import {getAllbalanceproductfile } from '../../api/balanceproductfile.api'

import './dashboardlistadoproductos.scss';






const Pdashboardlistadoproductos = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  	const [loftyendpoint_6560bec70eebbe3b7e300943, setLoftyEndpoint_6560bec70eebbe3b7e300943] = useState([]);
	
  useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_6560bec70eebbe3b7e300943 = await getAllbalanceproductfile();
			setLoftyEndpoint_6560bec70eebbe3b7e300943(loftyres_6560bec70eebbe3b7e300943.data);
		}
		loftyHandleData();
	}, []);

  
  
  

  if (!loftytoken) {
		return (<div>Requiere Autenticación</div>);
	}

  return (
    <div id="id654aa4cfc239522da2e3ba28" >

      <div id="fgfg" className="">
      
      <div id="iifg" className="gjs-row ">
      
      <div id="ig49" className="gjs-cell ">
      
      <div id="i99szh" className="">
      
        <a
          target=""
          className=""
          id="i9ymbj"
          href="#"
        >
          
      <img
        className=""
        id="i1d1f1"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr0oo7y.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iyxmw" className="">
      
        <a
          target=""
          className=""
          id="imqh5"
          href={`/dashboard`}
        >
          
      <div id="i0uip" className="itemnavigation ">
      
      <p className="" id="ij9fr">
        <span>Inicio</span>
      </p>
      
      </div>
      
        </a>
        
        <a
          target=""
          className=""
          id="iiwfn"
          href={`/dashboard/productos`}
        >
          
      <div id="i51wa" className="itemnavigationdefault ">
      
      <p className="" id="ib3yz">
        <span>Mis Productos</span>
      </p>
      
      </div>
      
        <a
          target=""
          className=""
          id="iia13"
          href="#"
        >
          
      <div id="ihupb" className="itemnavigation ">
      
      <p className="" id="irvrr">
        <span>Configuraciones</span>
      </p>
      
      </div>
      
        </a>
        
        </a>
        
      </div>
      
      <div id="igr3j" className="">
      
      <button
        type=""
        name=""
        id="i0k7v"
        className="buttoniniciosesion "
        onClick={async () => {
		deleteLoftytokenApp();
		window.location.href = '/signin';
}}

      >
      
      <p className="" id="isj6f">
        <span>
          Cerrar Sesion
        </span>
      </p>
      
      </button>
      
      </div>
      
      </div>
      
      <div id="io5ng" className="gjs-cell ">
      
      <div id="ipm2s" className="">
      
      <p className="" id="if76p">
        <span>Productos</span>
      </p>
      
      </div>
      
      <div id="i2c23" className="">
      
      <div id="iw7af" className="">
      
      <div id="i16zi" className="">
      
        <a
          target=""
          className="buttoniniciosesion "
          id="io5do"
          href="/dashboard/productos/agregar"
        >
          
    <div id="iqbei" className="">
    <span>Agregar Producto</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="ix48j" className="">
      
      <div id="ir7jg" className="itemproducto ">
      
      <p className="" id="iexft">
        <span>Etiqueta</span>
      </p>
      
      </div>
      
      <div id="i8ugf" className="itemproducto ">
      
      <p className="" id="idepg">
        <span>Balance</span>
      </p>
      
      </div>
      
      <div id="ihq9j" className="itemproducto ">
      
      <p className="" id="ik4wg">
        <span>Acción</span>
      </p>
      
      </div>
      
      </div>
      
        <div id="i2waf" className="">
        
        <div className="" id="izsvi">
        {
          loftyendpoint_6560bec70eebbe3b7e300943.map((loftyitem) => (
            <div key={loftyitem._id} className="">
              
        <div className="" id="iprma">
          
          <div className="" id="i5bcg">
            {loftyitem.undefined}
          </div>
          
        </div>
        
        <div className="" id="itg36">
          
          <div className="" id="">
            {loftyitem.undefined}
          </div>
          
        </div>
        
        <a
          target=""
          className=""
          id="ip7e1"
          href="#"
        >
          
    <div id="izhei" className="">
    <span>Ver más</span>
    </div>
    
        </a>
        
            </div>
          ))
        }
        </div>
        
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pdashboardlistadoproductos);
  