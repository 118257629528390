
import React from 'react';

import {connect} from 'react-redux';



import './error404.scss';






const Perror404 = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  

  
  return (
    <div id="id64aff0e88b8ac2e1bb827744" >

      <div id="iu8x" className="">
      
      <div id="in3m" className="">
      
      <div id="ipyf" className="">
      
      <div id="i29p" className="">
      
      <img
        className=""
        id="io5x"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk1558eu.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ii0y4">
        <span>Página No Encontrada</span>
      </p>
      
      <div id="ib1zf" className="">
      
        <a
          target=""
          className=""
          id="irp6h"
          href="/"
        >
          
    <div id="ixafu" className="">
    <span>Regresar</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Perror404);
  