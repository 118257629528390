
import React from 'react';

import {connect} from 'react-redux';



import './luis1.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pluis1 = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id64cc1fd09255464525645846" >

      <div id="i55h" className="">
      
      <div id="id6iic" className="">
      
        <a
          target=""
          className=""
          id="ikuk3f"
          href="/"
        >
          
      <img
        className=""
        id="i9pt4f"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr1t22z.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/quienessomos"
        type=""
      >
        <span>Quienes Somos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/aprende"
        type=""
      >
        <span>Aprende</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijf0dn"
        type=""
      >
        <span>Preguntas Frecuentes</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijn4q"
        type=""
      >
        <span>Contáctanos</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      <div id="izuf7m" className="">
      
        <a
          target=""
          className=""
          id="i3mkrh"
          href={`/quienessomos`}
        >
          
      <p className="" id="ilb64w">
        <span>Quiénes Somos</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ibhmfa"
          href="#"
        >
          
      <p className="" id="il1q3h">
        <span>Aprende</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="i7q4zk"
          href="/#ijf0dn"
        >
          
      <p className="" id="it6im4">
        <span>Preguntas Frecuentes</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ilbw8k"
          href="/#ijn4q"
        >
          
      <p className="" id="ixd91v">
        <span>Contáctanos</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      <div id="icv7c" className="">
      
      </div>
      
      <div id="ifxad2" className="">
      
      <div id="ijzglu" className="">
      
      <p className="" id="isdsyu">
        <span>¡Explora y aprende más acerca del potencial de tus recursos!</span>
      </p>
      
      <div id="icj91z" className="">
      
        <a
          target=""
          className=""
          id="ij0dp"
          href={`/gestionactiva1`}
        >
          
      <img
        className=""
        id="i5tgv"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkvp0adt.png"
        alt="undefined"
      />
      
      <p className="" id="icj75">
        <span>¿En qué consiste una gestión activa del flujo de caja en la empresa?</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ifjv9"
          href={`/gestionactiva2`}
        >
          
      <img
        className=""
        id="in12i"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkvp0adt.png"
        alt="undefined"
      />
      
      <p className="" id="i7ppv">
        <span>El Valor e Impacto de Realizar una Gestión Activa con la Liquidez de la EMPRESA</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ixh3h"
          href={`/gestionCFO`}
        >
          
      <img
        className=""
        id="i58m9"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkvp0adt.png"
        alt="undefined"
      />
      
      <p className="" id="i58ue">
        <span>¿Cómo te apoyamos en tu ROL de Gerente Financiero?</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i5of" className="">
      
      <div id="i8lmjf" className="">
      
      <div id="im4a28" className="">
      
      <img
        className=""
        id="iteqmg"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr5bykb.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iqpkke" className="">
      
      <p className="" id="iy8adh">
        <span>Dirección</span>
      </p>
      
      <p className="" id="iiauur">
        <span>Bogotá, Colombia</span>
      </p>
      
      </div>
      
      <div id="imh7h6" className="">
      
      <p className="" id="ijoril">
        <span>Correo de contacto</span>
      </p>
      
      <p className="" id="ik2c1u">
        <span>info@tret.com.co</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iw4n9w" className="">
      
      <div id="ianz3m" className="">
      
      </div>
      
      <div id="iufalf" className="">
      
      <p className="" id="ij1xap">
        <span>Coyright©2023 TRET - Todos los derechos reservados.</span>
      </p>
      
      <div id="ielirh" className="">
      
      <img
        className=""
        id="ii3z3k"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14hfve.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i1414e"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14i6xh.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="it3ooa"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14iokp.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pluis1);
  