
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AOS from 'aos';
import {connect} from 'react-redux';
import {addLoftyUser, deleteLoftytoken} from './actions/auth.actions';
import { loftyGetLoggedUser } from './api/user.api';

// pages
import inicio from './pages/inicio/inicio'
import luis21 from './pages/luis21/luis21'
import terminosycondiciones from './pages/terminosycondiciones/terminosycondiciones'
import khristian1 from './pages/khristian1/khristian1'
import khristian2 from './pages/khristian2/khristian2'
import khristian3 from './pages/khristian3/khristian3'
import luis1 from './pages/luis1/luis1'
import inicionuevo from './pages/inicionuevo/inicionuevo'
import nuevohome from './pages/nuevohome/nuevohome'
import iniciosesion from './pages/iniciosesion/iniciosesion'
import registrousuario from './pages/registrousuario/registrousuario'
import olvidarcontrasena from './pages/olvidarcontrasena/olvidarcontrasena'
import resetearcontrasena from './pages/resetearcontrasena/resetearcontrasena'
import dashboardinicial from './pages/dashboardinicial/dashboardinicial'
import dashboardlistadoproductos from './pages/dashboardlistadoproductos/dashboardlistadoproductos'
import verifiedemail from './pages/verifiedemail/verifiedemail';
import error404 from './pages/error404/error404'


// css
import './App.css';
import 'aos/dist/aos.css';

AOS.init();

function initSwiper() {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  // console.log('swiper :>> ', swiper);
}

const App = (props) => {
  const {loftytoken, addLoftyUserApp, deleteLoftytokenApp} = props;

  // agregando iniciación para el Swipper
  useEffect(() => {
    setTimeout(() => {
      initSwiper();
    }, 2000);
  }, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await loftyGetLoggedUser({ token: loftytoken });
        addLoftyUserApp(res.user);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        deleteLoftytokenApp();
      }
    }
    handleData();
  }, []);
  if (loading) {
    return <div>Loading ...</div>
  }


  return (
    <Router>
      <Switch>
			<Route exact path="/otraversion" component={inicio} />
			<Route exact path="/quienessomos" component={luis21} />
			<Route exact path="/terminosycondiciones" component={terminosycondiciones} />
			<Route exact path="/gestionactiva1" component={khristian1} />
			<Route exact path="/gestionactiva2" component={khristian2} />
			<Route exact path="/gestionCFO" component={khristian3} />
			<Route exact path="/aprende" component={luis1} />
			<Route exact path="/otraversion2" component={inicionuevo} />
			<Route exact path="/" component={nuevohome} />
			<Route exact path="/signin" component={iniciosesion} />
			<Route exact path="/signup" component={registrousuario} />
			<Route exact path="/forgotpassword" component={olvidarcontrasena} />
			<Route exact path="/reset-password/:id" component={resetearcontrasena} />
			<Route exact path="/dashboard" component={dashboardinicial} />
			<Route exact path="/dashboard/productos" component={dashboardlistadoproductos} />
      <Route exact path="/verify-email/:id" component={verifiedemail} />
			<Route exact path="*" component={error404} />

      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addLoftyUserApp: (user) => dispatch(addLoftyUser(user)),
  deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

