
import axios from '../config/axios';

export const url = '/api/newsletter'


export const createnewsletter = ({
  token,
	email,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && email ) {

    axios.post(`${url}//create/basic/6529927c65f10c3079b6671c`, {
		email,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!email) {
		reject({
			status: 'error',
			info: 'email no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      

