
import React from 'react';

import {connect} from 'react-redux';



import './khristian2.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pkhristian2 = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id64cc07c79255464525645680" >

      <div id="i55h" className="">
      
      <div id="id6iic" className="">
      
        <a
          target=""
          className=""
          id="ikuk3f"
          href="/"
        >
          
      <img
        className=""
        id="i9pt4f"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr1t22z.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/quienessomos"
        type=""
      >
        <span>Quienes Somos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/aprende"
        type=""
      >
        <span>Aprende</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijf0dn"
        type=""
      >
        <span>Preguntas Frecuentes</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/#ijn4q"
        type=""
      >
        <span>Contáctanos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      <div id="izuf7m" className="">
      
        <a
          target=""
          className=""
          id="i3mkrh"
          href={`/quienessomos`}
        >
          
      <p className="" id="ilb64w">
        <span>Quiénes Somos</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ibhmfa"
          href="#"
        >
          
      <p className="" id="il1q3h">
        <span>Aprende</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="i7q4zk"
          href={`/pruebasluis`}
        >
          
      <p className="" id="it6im4">
        <span>Preguntas Frecuentes</span>
      </p>
      
        </a>
        
        <a
          target=""
          className=""
          id="ilbw8k"
          href={`/pruebasluis`}
        >
          
      <p className="" id="ixd91v">
        <span>Contáctanos</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      <div id="icv7c" className="">
      
      </div>
      
      <div id="ifxad2" className="">
      
      <div id="ijzglu" className="">
      
      <p className="" id="isdsyu">
        <span>El Valor e Impacto de Realizar una Gestión Activa con la Liquidez de la EMPRESA</span>
      </p>
      
      <div id="icj91z" className="">
      
      <p className="" id="ibmtil">
        
      <u className="" id="">
        
      <b className="" id="">
        <span>Resumen</span>
      </b>
      
      <br className="" id="" />
      
      </u>
      
      <br className="" id="" />
      <span>Existen numerosos estudios que buscan determinar si la gestión activa en el mercado accionario puede generar una rentabilidad superior a los indicies accionarios. ¿Pero qué hay sobre el manejo de efectivo de las empresas? ¿Es posible generar mayores retornos a través de una gestión activa? La respuesta corta es SI.</span>
      <br className="" id="" />
      
      <i className="" id="">
        
      <b className="" id="">
        <span>*Gestión Pasiva:</span>
      </b>
      
      </i>
      <span> Cuando no realizas ninguno o pocos movimientos de liquidez en búsqueda de maximizar la rentabilidad de la liquidez circulante de la empresa.</span>
      <br className="" id="" />
      
      <i className="" id="">
        
      <b className="" id="">
        <span>*Gestión Activa:</span>
      </b>
      
      </i>
      <span> Cuando realizas movimientos de liquidez buscando maximizar su rentabilidad teniendo en cuenta la información del flujo de caja y de las mejores opciones del mercado.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <u className="" id="">
        
      <b className="" id="">
        <span>Metodología</span>
      </b>
      
      </u>
      
      <br className="" id="" />
      <span>En 2021, llevamos a cabo un estudio con 10 empresas que adoptaron una gestión pasiva, analizando su comportamiento y la rentabilidad generada a partir de su liquidez circulante. Simultáneamente, realizamos una simulación de la gestión activa para estimar el diferencial de rentabilidad que podrían haber obtenido. Posteriormente, en 2022 y 2023, observamos los resultados reales que se materializaron en empresas que optaron por la gestión activa, aprovechando las oportunidades brindadas por la herramienta TRET.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Esta comparativa nos proporcionó una visión más clara sobre el impacto de la gestión activa en la rentabilidad de las empresas, y los resultados obtenidos en 2022 y 2023 respaldan los beneficios potenciales que puede brindar la adopción de esta estrategia.</span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>Nota.</span>
      </b>
      <span> Es importante anotar que poder hacer estos movimientos de liquidez a través de la “Fiducuenta” de la empresa permitirá poder utilizar diferentes bancos sin tener que incurrir en el 4x1000, siempre y cuando todos sus productos estén asociados a un mismo número de NIT.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      
      <b className="" id="">
        
      <u className="" id="">
        <span>Resultados</span>
      </u>
      
      </b>
      
      <br className="" id="" />
      <span>Sobre los últimos tres años de simulación (Backtesting), TRET superó considerablemente la gestión pasiva que se puede estar observando en las empresas del sector real. Las empresas que tuvieron una gestión activa con TRET lograron tener una rentabilidad superior de 10% en 2023 a las empresas que mantuvieron una gestión pasiva.</span>
      <span className="" id="igmzke">
        
      </span>
      
      </p>
      
      <div id="idfned" className="">
      
      <img
        className=""
        id="itxz8c"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkvlu8s3.jpg"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="ivud6j">
        
      <a
        target="_blank"
        className=""
        id="ihb7h4"
        href="/gestionactiva2"
        type=""
      >
        <span>¿Cómo lograr hacer una gestión activa? </span>
      </a>
      
      <br className="" id="" />
      
      <b className="" id="">
        
      <u className="" id="">
        <span>Conclusiones:</span>
      <br className="" id="" />
      
      </u>
      
      </b>
      <span>Tener una gestión activa nos ayudará como empresa a:</span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>1.</span>
      </b>
      <span> Generar más rentabilidad con la liquidez de tu empresa</span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>2.</span>
      </b>
      <span> Reducir costos financieros </span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>3. </span>
      </b>
      <span>Tener mayor control sobre las finanzas de tu empresa</span>
      <br className="" id="" />
      <span> con resultados y la experiencia del equipo de TRET</span>
      <br className="" id="" />
      
      <b className="" id="">
        <span>Empieza ya!</span>
      </b>
      
      <br className="" id="" />
      <span>Haz fácil una buena gestión, teniendo las respuestas a la mano.  En TRET estamos comprometidos con acompañar y brindar + tranquilidad + tiempo + dinero a nuestros clientes.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i5of" className="">
      
      <div id="i8lmjf" className="">
      
      <div id="im4a28" className="">
      
      <img
        className=""
        id="iteqmg"
        src="https://assetsprojects.s3.amazonaws.com/405opfilkr5bykb.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iqpkke" className="">
      
      <p className="" id="iy8adh">
        <span>Dirección</span>
      </p>
      
      <p className="" id="iiauur">
        <span>Bogotá, Colombia</span>
      </p>
      
      </div>
      
      <div id="imh7h6" className="">
      
      <p className="" id="ijoril">
        <span>Correo de contacto</span>
      </p>
      
      <p className="" id="ik2c1u">
        <span>info@tret.com.co</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iw4n9w" className="">
      
      <div id="ianz3m" className="">
      
      </div>
      
      <div id="iufalf" className="">
      
      <p className="" id="ij1xap">
        <span>Coyright©2023 TRET - Todos los derechos reservados.</span>
      </p>
      
      <div id="ielirh" className="">
      
      <img
        className=""
        id="ii3z3k"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14hfve.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="i1414e"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14i6xh.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="it3ooa"
        src="https://assetsprojects.s3.amazonaws.com/405gzoilk14iokp.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Pkhristian2);
  