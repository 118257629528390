
import {ADD_TOKEN, ADD_USER, DELETE_TOKEN} from '../types/auth.types';

const initialState = {
  loftytoken: localStorage.getItem('loftytoken'),
  loftyuser: undefined,
};

const reducerAuth = (state = initialState, {type, payload}) => {
  switch (type) {
    case ADD_TOKEN:
      return {
        ...state,
        token: payload?.loftytoken,
        loftyuser: payload?.loftyuser,
      };
    case DELETE_TOKEN:
      return {
        ...state,
        token: undefined,
        loftyuser: undefined,
      };
    case ADD_USER:
      return {
        ...state,
        loftyuser: payload?.loftyuser,
      };
    default:
      return state;
  }
};

export default reducerAuth;
