
import axios from '../config/axios';

export const url = '/api/balanceproductfile'


export const createbalanceproductfile = ({
  token,
	producto,
	amount,
	tag,
	intituto,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && producto && amount && tag && intituto ) {
		const formData = new FormData();
		formData.append('loftyFile', loftyFile);
		formData.append('producto', producto);
		formData.append('amount', amount);
		formData.append('tag', tag);
		formData.append('intituto', intituto);

    axios.post(`${url}//create/basic/6560bd820eebbe3b7e30089f`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!producto) {
		reject({
			status: 'error',
			info: 'producto no esta definido',
		});
	}  else if (!amount) {
		reject({
			status: 'error',
			info: 'amount no esta definido',
		});
	}  else if (!tag) {
		reject({
			status: 'error',
			info: 'tag no esta definido',
		});
	}  else if (!intituto) {
		reject({
			status: 'error',
			info: 'intituto no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      
export const getAllbalanceproductfile = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}//list/basic/6560bec70eebbe3b7e300943`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      

