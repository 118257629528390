
import React from 'react';

import { connect } from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import { loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword } from '../../api/user.api';


import './iniciosesion.scss';






const Piniciosesion = ({ match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp }) => {

  return (
    <div id="id653f9fc9c239522da2e3a5ff" >

      <div id="fgfg" className="">

        <div id="i99szh" className="">

          <a
            target=""
            className=""
            id="i9ymbj"
            href="#"
          >

            <img
              className=""
              id="i1d1f1"
              src="https://assetsprojects.s3.amazonaws.com/405opfilkr0oo7y.png"
              alt="undefined"
            />

          </a>

        </div>

        <div id="ix5d2" className="">

          <div id="iw403" className="">

            <p className="" id="iegvfq">
              <span>Inicio de sesión</span>
            </p>

            <div>

              <form
                id="i1rtx5"
                className=""
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    const loftyEmail = e.target.loftyEmail?.value;
                    const loftyPassword = e.target.loftyPassword?.value;
                    const res = await loftyLoginWithEmail({ loftyEmail, loftyPassword })
                    addLoftyTokenApp({ loftytoken: res.token, loftyuser: res.user });
                    window.location.href = '/dashboard';
                  } catch (error) {
                    if (error.info) {
                      alert(error.info);
                    } else {
                      alert('Ocurrio un error !!')
                    }
                    console.log(error);
                  }
                }}

              >

                <div id="ig8bfm" className="">

                  <label id="if0rak" className="labeliniciosesion ">
                    <span>Correo</span>
                  </label>

                  <br className="" id="" />

                  <input
                    name="loftyEmail"
                    type="text"
                    className="inputdark "
                    id="i9xp6d"
                  />

                </div>

                <div id="i70q4z" className="">

                  <label id="icq1cg" className="labeliniciosesion ">
                    <span>Contraseña</span>
                  </label>

                  <br className="" id="" />

                  <input
                    name="loftyPassword"
                    type="password"
                    className="inputdark "
                    id="iermd6"
                  />

                </div>

                <div id="ixqti" className="">

                  <a
                    target=""
                    className=""
                    id="iuz4w"
                    href={`/forgotpassword`}
                  >

                    <div id="i9u6i" className="">
                      <span>¿Ólvidaste tu contraseña?</span>
                    </div>

                  </a>

                </div>

                <div id="il488" className="">

                  <button
                    type="submit"
                    name=""
                    id="il3hc3"
                    className="buttoniniciosesion "

                  >
                    <span>Iniciar</span>
                  </button>

                </div>

              </form>

            </div>

            <div id="ikf7l" className="">

              <a
                target=""
                className="buttoniniciosesion "
                id="i6z1y"
                href="/signup"
              >

                <div id="igrxm" className="">
                  <span>Registrarse</span>
                </div>

              </a>

            </div>

          </div>

        </div>

      </div>

    </div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
  deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Piniciosesion);
